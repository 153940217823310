<template lang="html">
  <div>
    <div>
      <ul class="nav nav-pills">
        <li class="nav-item"><a class="nav-link active" href="#image" id="btn_image" data-toggle="tab">图片</a></li>
        <li class="nav-item"><a class="nav-link" href="#pano" id="btn_pano" data-toggle="tab">全景</a></li>
      </ul>
    </div>
    <div style="height:10px"></div>
    <div class="tab-content">
      <div class="container tab-pane active" id="image">
        <div><button class="btn btn-primary" @click="imgChoose" :disabled="!weixinReady">选择</button></div>
        <UploadBox ref="imgUploadBox"
                   action="/Api/My/Files/Upload"
                   :param="param"
                   :multiple="true"
                   :maxSize="2"
                   :maxWidth="2500"
                   :maxHeight="2500"
                   :compress="true"
                   :isPano="false"
                   :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                   :partSizeMB="10"
                   :surlheight="100"
                   :surlwidth="200"
                   :maxUpper="3"
                   :uploadFinished="search"
                   :changeName="changeName"
                   :showButton="false">
        </UploadBox>
        <router-link :to="{ name: 'LibList'}" class="info-box-number text-right">前往素材库</router-link>
      </div>
      <div class="container tab-pane" id="pano">
        <div><button class="btn btn-primary" @click="PanoChoose" :disabled="!weixinReady">选择</button></div>
        <UploadBox ref="PanoUploadBox"
                   action="/Api/My/Panos/Upload"
                   :param="param"
                   :multiple="true"
                   :maxSize="50"
                   :maxWidth="22000"
                   :maxHeight="11000"
                   :isPano="true"
                   :allowExtensions="/(.jpg|.jpeg)$/i"
                   :partSizeMB="10"
                   :surlheight="100"
                   :surlwidth="200"
                   :maxUpper="3"
                   :uploadFinished="search"
                   :changeName="changePanoName"
                   :showButton="false">
        </UploadBox>
        <router-link :to="{ name: 'PanoList'}" class="info-box-number text-right">前往全景管理</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { UploadBox } from 'common'
  //import UploadBox from '@/components/UploadBox'

  export default {
    components: {
      UploadBox,
    },
    data() {
      return {
        weixinReady: false,
      }
    },
    created() {
      const apiUrl = '/Api/Weixin/Share'
      const config = {
        params: { url: location.href }
      }
      //alert(wx)
      this.$axios.get(apiUrl, config).then((data) => {
        //获取正确的签名数据
        this.$wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.data.appId, // 必填，公众号的唯一标识
          timestamp: data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
          signature: data.data.signature, // 必填，签名，见附录1
          jsApiList: ['chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData', 'startRecord', 'stopRecord', 'getLocalVoiceData'] //必填，需要使用的JS-SDK接口列表
        })
        this.$wx.ready(() => {
          this.weixinReady = true
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });
        this.$wx.error((res) => {
          alert('微信加载错误：' + res)
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      }).catch((err) => {
        alert(err)
      })
      //this.$remoteLoad('https://res.wx.qq.com/open/js/jweixin-1.5.0.js').then(() => {
      //})
    },
    computed: {
    },
    methods: {
      changeName(item, res) {
        var _this = this;
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.name = item.title;
          console.log(data)
          this.$axios.put('/Api/My/Files/' + data.guid, data).then(function (res) {
            if (res.status == 200) {
              _this.search()
            }
          })
        }
      },
      changePanoName(item, res) {
        var _this = this;
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.title = item.title;
          console.log(data)
          this.$axios.put('/Api/My/Panos/' + data.guid, data).then(function (res) {
            if (res.status == 200) {
              _this.search()
            }
          })
        }
      },
      imgChoose() {
        this.$wx.chooseImage({
          count: 9, // 默认9
          sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
          success: (res) => {
            try {
              this.res = res;
              var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
              this.imgUpload(localIds)
            } catch (e) {
              alert(e)
            }
          }
        });
      },
      imgUpload(imgs) {
        for (var i in imgs) {
          //wx.uploadImage({
          //    localId: localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
          //    isShowProgressTips: 1, // 默认为1，显示进度提示
          //    success: (res) => {
          //        var serverId = res.serverId; // 返回图片的服务器端ID
          //    }
          //});
          this.$wx.getLocalImgData({
            localId: imgs[i], // 图片的localID
            success: (res) => {
              var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
              this.localData = localData
              var blob = this.dataURLtoBlob(`data:image/jpeg;base64,${localData}`)
              blob.name = `${this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}.jpg`
              this.$refs.imgUploadBox.$refs.Upload.addFile({ target: { files: [blob] } })

              //this.base64toblob(`data:image/jpeg;base64,${localData}`).then((data) => {
              //    this.files.push(data)
              //}).catch((err) => {
              //    alert(err)
              //})
            }
          });
        }
      },
      PanoChoose() {
        this.$wx.chooseImage({
          count: 9, // 默认9
          sizeType: ['original'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
          success: (res) => {
            try {
              this.res = res;
              var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
              this.PanoUpload(localIds)
            } catch (e) {
              alert(e)
            }
          }
        });
      },
      PanoUpload(imgs) {
        for (var i in imgs) {
          //wx.uploadImage({
          //    localId: localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
          //    isShowProgressTips: 1, // 默认为1，显示进度提示
          //    success: (res) => {
          //        var serverId = res.serverId; // 返回图片的服务器端ID
          //    }
          //});
          this.$wx.getLocalImgData({
            localId: imgs[i], // 图片的localID
            success: (res) => {
              var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
              this.localData = localData
              alert('localData长度：' + localData.length)
              var blob = this.dataURLtoBlob(`data:image/jpeg;base64,${localData}`)
              blob.name = `${this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}.jpg`
              alert('blob大小：' + blob.size)
              this.$refs.PanoUploadBox.$refs.Upload.addFile({ target: { files: [blob] } })
            }
          });
        }
      },
      dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      },
      //base64toblob(localData) {
      //    return new Promise((reasolve, reject) => {
      //        var data = {}
      //        var image = new Image();
      //        image.onload = () => {
      //            var ps = []

      //            data.width = image.width
      //            data.height = image.height
      //            var canvas1 = document.createElement('canvas');
      //            var context1 = canvas1.getContext('2d');
      //            // 最大尺寸限制
      //            var maxWidth = 200, maxHeight = 200;
      //            var originWidth = image.width;
      //            var originHeight = image.height;
      //            // 目标尺寸
      //            var targetWidth = originWidth, targetHeight = originHeight;
      //            // 图片尺寸超过400x400的限制
      //            if (originWidth > maxWidth || originHeight > maxHeight) {
      //                if (originWidth / originHeight > maxWidth / maxHeight) {
      //                    // 更宽，按照宽度限定尺寸
      //                    targetWidth = maxWidth;
      //                    targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      //                } else {
      //                    targetHeight = maxHeight;
      //                    targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      //                }
      //            }
      //            canvas1.width = targetWidth
      //            canvas1.height = targetHeight
      //            // 清除画布
      //            context1.clearRect(0, 0, targetWidth, targetHeight);
      //            // 图片压缩
      //            context1.drawImage(image, 0, 0, targetWidth, targetHeight);
      //            data.surl = canvas1.toDataURL('image/jpeg')
      //            canvas1.width = image.width
      //            canvas1.height = image.height
      //            // 清除画布
      //            context1.clearRect(0, 0, image.width, image.height);
      //            // 图片压缩
      //            context1.drawImage(image, 0, 0, image.width, image.height);
      //            canvas1.toBlob((blob) => {
      //                //blob.filename = '1.jpg'
      //                data.file = blob
      //                reasolve(data)
      //            }, 'image/jpeg')
      //            //ps.push(new Promise((rs, re) => {
      //            //}))
      //            //Promise.all(ps).then(() => {
      //            //    reasolve(data)
      //            //})
      //        }
      //        image.src = localData
      //    })
      //},
      //previewImage() {
      //    this.$wx.previewImage({
      //        current: location.href, // 当前显示图片的http链接
      //        urls: this.files // 需要预览的图片http链接列表
      //    });
      //},
      //testWidthHeight(Base64) {
      //    return new Promise((reasolve, reject) => {
      //        var isAllow = true;
      //        var canvas = document.createElement('canvas');
      //        var context = canvas.getContext('2d');
      //        var data = e.target.result;
      //        //加载图片获取图片真实宽度和高度
      //        var image = new Image();
      //        image.onload = () => {
      //            var width = image.width;
      //            var height = image.height;
      //            if (this.isPano) {
      //                isAllow = isAllow ? width / height == 2 : false;
      //            }
      //            if (!isAllow) {
      //                reject("不是 2:1 柱面全景图片")
      //                return;
      //            }
      //            if (this.maxWidth) {
      //                isAllow = isAllow ? width <= this.maxWidth : false;
      //            }
      //            if (this.maxHeight) {
      //                isAllow = isAllow ? height <= this.maxHeight : false;
      //            }
      //            //if (!isAllow) {
      //            //    if (this.compress) {
      //            //        switch (fileData.type) {
      //            //            case 'imgae/png':
      //            //            case 'image/gif':
      //            //                reject("超过图片尺寸限制 (" + this.maxWidth + "x" + this.maxHeight + ")")
      //            //                return;
      //            //                break;
      //            //            default:
      //            //                fileData.needcompress = true;
      //            //                break;
      //            //        }
      //            //    } else {
      //            //        reject("超过图片尺寸限制 (" + this.maxWidth + "x" + this.maxHeight + ")")
      //            //        return;
      //            //    }
      //            //}
      //            if (this.surlwidth && this.surlheight) {
      //                var originWidth = this.width;
      //                var originHeight = this.height;

      //                // 最大尺寸限制
      //                var maxWidth = this.surlwidth, maxHeight = this.surlheight;
      //                // 目标尺寸
      //                var targetWidth = originWidth, targetHeight = originHeight;
      //                // 图片尺寸超过400x400的限制

      //                if (originWidth > maxWidth || originHeight > maxHeight) {
      //                    if (originWidth / originHeight > maxWidth / maxHeight) {
      //                        // 更宽，按照宽度限定尺寸
      //                        targetWidth = maxWidth;
      //                        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      //                    } else {
      //                        targetHeight = maxHeight;
      //                        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      //                    }
      //                }
      //                // canvas对图片进行缩放
      //                canvas.width = targetWidth;
      //                canvas.height = targetHeight;
      //                // 清除画布
      //                context.clearRect(0, 0, targetWidth, targetHeight);
      //                // 图片压缩
      //                context.drawImage(image, 0, 0, targetWidth, targetHeight);

      //                fileData.surl = canvas.toDataURL(fileData.type);
      //            }
      //            reasolve(fileData)
      //        }
      //        image.onerror = (err) => {
      //            reject('图片读取错误')
      //        }
      //        image.src = Base64;
      //    })
      //},
      //compressFile(file) {
      //    return new Promise((reasolve, reject) => {
      //        if (!file.needcompress) {
      //            reasolve(file)
      //            return
      //        }
      //        var self = this;
      //        console.log(file);
      //        //读取图片数据
      //        var reader = new FileReader();
      //        reader.onload = (e) => {
      //            var canvas = document.createElement('canvas');
      //            var context = canvas.getContext('2d');
      //            var data = e.target.result;
      //            //加载图片获取图片真实宽度和高度
      //            var image = new Image();
      //            image.onload = () => {
      //                var width = image.width;
      //                var height = image.height;
      //                var originWidth = this.width;
      //                var originHeight = this.height;
      //                var surl
      //                if (self.surlwidth && self.surlheight) {
      //                    var originWidth = this.width;
      //                    var originHeight = this.height;

      //                    // 最大尺寸限制
      //                    var maxWidth = self.surlwidth, maxHeight = self.surlheight;
      //                    // 目标尺寸
      //                    var targetWidth = originWidth, targetHeight = originHeight;
      //                    // 图片尺寸超过400x400的限制

      //                    if (originWidth > maxWidth || originHeight > maxHeight) {
      //                        if (originWidth / originHeight > maxWidth / maxHeight) {
      //                            // 更宽，按照宽度限定尺寸
      //                            targetWidth = maxWidth;
      //                            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      //                        } else {
      //                            targetHeight = maxHeight;
      //                            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      //                        }
      //                    }
      //                    // canvas对图片进行缩放
      //                    canvas.width = targetWidth;
      //                    canvas.height = targetHeight;
      //                    // 清除画布
      //                    context.clearRect(0, 0, targetWidth, targetHeight);
      //                    // 图片压缩
      //                    context.drawImage(image, 0, 0, targetWidth, targetHeight);

      //                    surl = canvas.toDataURL(file.type);
      //                }
      //                // 最大尺寸限制
      //                var maxWidth = self.maxWidth, maxHeight = self.maxHeight;
      //                // 目标尺寸
      //                var targetWidth = originWidth, targetHeight = originHeight;
      //                // 图片尺寸超过400x400的限制

      //                if (originWidth > maxWidth || originHeight > maxHeight) {
      //                    if (originWidth / originHeight > maxWidth / maxHeight) {
      //                        // 更宽，按照宽度限定尺寸
      //                        targetWidth = maxWidth;
      //                        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      //                    } else {
      //                        targetHeight = maxHeight;
      //                        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      //                    }
      //                }
      //                // canvas对图片进行缩放
      //                canvas.width = targetWidth;
      //                canvas.height = targetHeight;
      //                // 清除画布
      //                context.clearRect(0, 0, targetWidth, targetHeight);
      //                // 图片压缩
      //                context.drawImage(image, 0, 0, targetWidth, targetHeight);
      //                var filename = file.name;
      //                canvas.toBlob((blob) => {
      //                    blob.surl = surl;
      //                    blob.name = filename;
      //                    self.$message({
      //                        message: blob.name + " 已压缩为 (" + targetWidth + "x" + targetHeight + ") " + Math.floor(blob.size / 1024) + ' KB',
      //                        type: 'info',
      //                        zIndex: 9999,
      //                        //showClose: true
      //                    })
      //                    reasolve(blob)
      //                    //self.addFileToList(blob)
      //                }, file.type, 0.9)
      //            }
      //            image.src = data;
      //        };
      //        reader.readAsDataURL(file);
      //    })
      //}
    },
  }
</script>

<style scoped>
</style>
